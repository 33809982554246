import React, { useEffect, useState } from "react";
import {
  Grid,
  Link,
  Typography,
  makeStyles,
  Box,
  Container,
  Paper,
} from "@material-ui/core";
import Logo from "./../../component/Logo";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  loginLayoutBox: {
    background: theme.palette.background.white,
    display: "flex",
    zIndex: "9",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    // alignItems: 'center',
    "& .loginLayoutBoxImg": {
      width: "55%",
      zIndex: "1",
      position: "fixed",
      backgroundSize: "cover",
      backgroundImage: "url(./images/banner_login.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: theme.palette.background.white,
    position: "absolute",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
}));

export default function LoginLayout({ children }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.loginLayoutBox}>
        <Box className="loginLayoutBoxImg">
          <Box align="center">
            <Typography variant="h4" style={{ color: "#fff" }}>
              Welcome to
            </Typography>
            <Box mt={1} mb={2}>
              <RouterLink to="/" style={{ textDecoration: "none" }}>
                <Typography variant="h1" style={{ color: "#fff" }}>
                  Gyan Batua
                </Typography>
              </RouterLink>
            </Box>

            <Typography variant="h3" style={{ color: "#fff" }}>
              {" "}
              Dashboard
            </Typography>
          </Box>
        </Box>
        <Box className={classes.loginContentLayoutBox}>
          <Box style={{ width: "100%" }}>{children}</Box>
        </Box>
        {/* 
        <Box style={{ width: '100%' }}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={7}>
              <img src="images/banner_login.jpeg" alt="images" />
            </Grid>
            <Grid item lg={5}>
              <Box style={{ width: '100%' }}>{children}</Box>
            </Grid>
          </Grid>
        </Box> */}
      </Box>
    </>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
