import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,

    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,

    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },

  {
    exact: true,
    guard: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/AddUniversity")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,

    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Signup")),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },

  {
    exact: true,
    guard: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Setting/BotSetting")
    ),
  },
  {
    exact: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/FAQ",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection")),
  },
  {
    exact: true,
    path: "/term-and-condition",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/add-enterprises",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EnterPrises/index")
    ),
  },
  {
    exact: true,
    path: "/view-enterprises",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EnterPrises/AddEnterPrisesList")
    ),
  },
  {
    exact: true,
    path: "/degree-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/degree/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-degree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/degree/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/ViewUniversity")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/EditUniversity")
    ),
  },
  {
    exact: true,
    path: "/domain-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/domain/index")),
  },
  {
    exact: true,
    path: "/skills-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/skills/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-domain",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/domain/index")),
  },
  {
    exact: true,
    path: "/subject-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/subject/index")),
  },
  {
    exact: true,
    path: "/add-subject",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/subject/index")),
  },
  {
    exact: true,
    path: "/course-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/course/index")),
  },
  {
    exact: true,
    path: "/faculity-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/faculity/index")),
  },
  {
    exact: true,
    path: "/add-faculity",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/faculity/index")),
  },
  {
    exact: true,
    path: "/query-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/querylist/QueryList")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserList")
    ),
  },
  {
    exact: true,
    path: "/create-badges",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/automation/Badge")),
  },
  {
    exact: true,
    path: "/create-announcement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/announcement")),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserTabs")
    ),
  },
  {
    exact: true,
    path: "/transaction-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/transaction/TransactionMangement")
    ),
  },
  {
    exact: true,
    path: "/gyan-coins",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/gyancoins/GyanCoins")
    ),
  },
  // {
  //   exact: true,
  //   path: "/automation",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/automation/Automation")
  //   ),
  // },
  {
    exact: true,
    path: "/university-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/UniversityList")
    ),
  },
  {
    exact: true,
    path: "/transaction",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Transaction")),
  },
  {
    exact: true,
    path: "/attendance",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Attendence")),
  },
  {
    exact: true,
    path: "/challenges",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Challenges")),
  },
  {
    exact: true,
    path: "/employer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Employer/index")),
  },
  {
    exact: true,
    path: "/resume",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Resume/index")),
  },
  {
    exact: true,
    path: "/particular-faculity-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/particularfaculity/ParticularFaculity")),
  },
  {
    exact: true,
    path: "/add-technology",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/technology/index")),
  },
  {
    exact: true,
    path: "/admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Admin")),
  },
  {
    exact: true,
    path: "/subadmin-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/SubAdmin")
    ),
  },
  {
    exact: true,
    path: "/view-subadmin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/ViewParticularSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/certificate-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/certificaterequest/CertificateRquest")
    ),
  },
  {
    exact: true,
    path: "/course-category",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/index")
    ),
  },
  {
    exact: true,
    path: "/course-category/subcategory",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/courseSubCategory/index")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
