import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiAppBar: {
      positionFixed: {
        // background: "transparent !important",
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "auto",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        padding: "0px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "460px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    paperWidthSm: {
      maxWidth: "600px",
      padding: "20px 30px",
    },
    MuiCollapse: {
      wrapperInner: {
        paddingLeft: "44px",
      },
    },

    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",
        // color: "rgb(255, 125, 104) !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
      },
    },
    MuiPickerDTToolbar: { toolbar: { borderRadius: "8px 8px 0px 0px" } },

    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "5px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "10px",
        height: "45px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlinedSizeLarge: {
        padding: "7px 35px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiMenu: {
        list: {
          outline: "0",
          background: "#ffffff",
        },
      },
      MuiDialog: {
        paper: {
          margin: "32px",
          background: "#ffffff",
          padding: "20px 30px",
          position: "relative",
          overflowY: "auto",
          color: "#fff !important",
          border: "2px solid #615dfa",
          borderRadius: "10px !important",
          "@media(max-width:767px)": {
            padding: "10px",
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: "#23d2e2 !important",
        },
        track: {
          backgroundColor: "#a8a4a4",
          opacity: "1",
        },
      },
      MuiInputBase: {
        root: {
          color: "#000",
          height: "50px",

          background: "rgba(0, 0, 0, 0.04)",
          borderRadius: "10px !important",
          // height: "50px !important",
        },
      },
      MuiSelect: {
        icon: {
          color: "#000",
        },
      },
      MuiTableHead: {
        root: {
          background: "transparent",
          borderTop: "1px solid #b3aaaa",
        },
      },
      MuiTableBody: {
        root: {
          background:
            "linear-gradient(152.97deg, rgb(255 255 255 / 65%) 0%, rgb(62 60 60 / 27%) 100%)",
        },
      },
      MuiTableRow: {
        root: {
          borderBottom: "1px solid #00bcd438",
          "&:hover": {
            backgroundColor: "#00000017",
          },
          "&:last-child": {
            borderBottom: "none",
          },
        },
      },
      MuiTableCell: {
        head: {
          color: "#fff",
          padding: "8px 16px",
          fontWeight: "500",
          lineHeight: "2.5rem",
          background: "#615dfa",
        },
        body: {
          color: "#3e3f5e",
          background: "#fff",
          fontWeight: "500",
          boxShadow: "0 0 40px 0 rgba(94,92,154, .06)",
        },
      },

      MuiButton: {
        containedPrimary: {
          backgroundColor: "#615dfa",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          boxShadow:
            "0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)",
          "&:hover": {
            color: "#ffffff",
            backgroundColor: "rgb(67, 65, 175)",
            boxShadow:
              "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
          },
        },

        containedSecondary: {
          borderRadius: "10px",
          color: "#000",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          marginRight: "10px",

          background: "transparent",
          boxShadow:
            "0 1px 0 0 #ad5165, 0 -1px 0 0 #7e46a1, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#fff",
            background:
              "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
          },
        },

        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.03) ",
          },
        },
        outlinedPrimary: {
          color: "#000",
          border: "2px solid #615dfa ",
          fontWeight: "500",
          "&:hover": {
            color: "#615dfa",
            boxShadow: "none !important",
          },
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: { color: "#000" },
      },
      MuiPickersClockNumber: { clockNumber: { color: "#000" } },
      MuiPickersDay: {
        day: {
          color: "#000",
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#000",
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#000",
        },
      },
      MuiPaper: {
        root: { color: "#000" },
        elevation2: {
          position: "relative",
          zIndex: "999",
          overflow: "hidden",
          padding: "40px",
          boxShadow: "0 0 40px 0 rgba(94,92,154, .06)",
          borderRadius: "10px !important",
          backgroundColor: "#fff",
          "@media(max-width:767px)": {
            padding: "20px !important",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#000000",
        },
      },
      MuiFormLabel: {
        root: {
          "&.Mui-focused": {
            color: "#615dfa",
            border: "#615dfa",
          },
        },
      },

      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            // border: "none",
            boxShadow: "none",
          },
        },
        notchedOutline: {
          background: "rgba(0, 0, 0, 0.07)",
          borderColor: "rgb(230 226 230)",
        },
        input: {
          borderRadius: "10px",
          color: "#000",
          padding: "16px 14px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#000",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
    },
    typography: {
      fontFamily: "'Saira', sans-serif",
    },
    palette: {
      background: {
        card: "linear-gradient(152.97deg, rgb(168 164 164 / 10%) 0%, rgb(66 64 64 / 19%) 100%)",
        tab: "#fff",
        header: "#fff",
        default: "#F5F5F5",
        price: "#fef7fa",
        pricename: "#fef7fa",
        blackCard: "#fef7fa",
        tablehead: "#fef7fa",
      },
      primary: {
        main: "#3e3f5e", //black
      },
      secondary: {
        main: "#000000", //black
        icons: "#009900", //white
      },
      text: {
        primary: "#3e3f5e", //black
        secondary: "#3e3f5e", //white
        gray: "#000",
        graydark: "#000",
        white: "#000",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
